// NOTE: THIS FILE IS SHARED BY BOTH CLIENT-SIDE & SERVER-SIDE. AVOID USAGE OF NON-JS CODE

/**
 * Generates Event Identifier
 * @param {number} digits Amount of digits to take from the random
 * @returns {string} Random generated Identifier
 */
function generateId(digits) {
    // eslint-disable-next-line prefer-exponentiation-operator
    var rnd = Math.random() * Math.pow(10, digits);
    var random = String('00000' + rnd.toFixed().toString()).slice(-digits);
    return Date.now() + '.' + random;
}

module.exports = generateId;
