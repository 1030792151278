/**
 * Executes all functions in SFRA way
 * @param {Function|Object} include function or object with functions
 */
function processInclude(include) {
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach(function (key) {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
}

import analytics from './analytics/analytics';

$(document).ready(function () {
    processInclude(analytics);
});
