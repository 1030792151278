const generateId = require('../../../../shared/generateId');

/**
 * Helpers
 */

/**
 * Checks if element is vertically in a viewport
 * @param {jQuery} element - jQuery object
 * @param {number} toleratedMargin - tolerated margin
 * @returns {boolean} - returns true or false if element is in the viewport or not
 */
function isInViewport(element, toleratedMargin = 0) {
    if (!element) {
        return false;
    }

    const viewPortMin = 0 - toleratedMargin;
    const viewPortMax = window.innerHeight + toleratedMargin;
    const elementRect = element.getBoundingClientRect();
    const elementMin = elementRect.top;
    const elementMax = elementRect.top + elementRect.height;

    if (elementMin > viewPortMin && elementMin < viewPortMax) {
        return true;
    }

    if (elementMax > viewPortMin && elementMax < viewPortMax) {
        return true;
    }

    return false;
}

/**
 * Merge Objects
 * @param {Object} dest Object
 * @param {Object} src Object
 */
function merge(dest, src) {
    Object.keys(src).forEach(function (key) {

        if (!(key in dest)) {
            dest[key] = src[key];
            return;
        }

        if (typeof dest[key] !== typeof src[key]) {
            throw Error('Should not happen');
        }

        if (Array.isArray(dest[key])) {
            dest[key] = dest[key].concat(src[key]);
            return;
        }

        if (typeof dest[key] === 'object') {
            merge(dest[key], src[key]);
            return;
        }

        dest[key] = src[key];
    });
}

/**
 * Variables
 */
var elements = {
    $document: $(document)
};

var cache = {
    observer: null,
    isInteractionObserver: 'IntersectionObserver' in window
};

/**
 * Methods
 */

/**
 * Adds view listener to page
 * @returns {void}
 */
function onViewInit() {
    var viewElements = document.querySelectorAll('[data-ga-view]');

    if (viewElements.length === 0) return;

    if (cache.isInteractionObserver) {
        const config = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        if (cache.observer) {
            cache.observer.disconnect();
        }

        cache.observer = new IntersectionObserver(viewHandler, config);
        viewElements.forEach(viewElement => cache.observer.observe(viewElement));
    } else {
        viewHandler(viewElements);
    }
}

/**
 * Handles View elemements on the screen, can be through IntersectionObserver or scroll (old browsers)
 * @param {NodeListOf<Element>|IntersectionObserverEntry[]} elements Elements
 * @param {IntersectionObserver} [observer] IntersectionObserver
 */
function viewHandler(elements, observer) {
    var eventTypes = {};
    var isInView = false;

    elements.forEach((element) => {
        if (!cache.isInteractionObserver) {
            isInView = isInViewport(element);
        }

        if (isInView || element.isIntersecting) {
            if (cache.isInteractionObserver) {
                element = element.target;
                observer.unobserve(element);
            }

            if (element.dataset.vt) return;

            var gaData = element.dataset.gaView;
            var gaObj = parseEvent(gaData, element, '[data-ga-view]');
            if (gaObj.event in eventTypes) {
                eventTypes[gaObj.event].push(gaObj);
            } else {
                eventTypes[gaObj.event] = [gaObj];
            }

            element.dataset.vt = true;
        }
    });

    Object.keys(eventTypes).forEach(function (key) {
        var events = eventTypes[key];

        var mergedEvent = null;

        events.forEach(function (event) {
            if (!mergedEvent) {
                mergedEvent = event;
                return;
            }

            merge(mergedEvent, event);
        });

        if (mergedEvent) pushEvent(mergedEvent);
    });
}

/**
 * @description Pushes GA events to the data layer if exists
 * @param {Object} data - GA Events in array to be pushed
 */
function onAjaxResponse(data) {
    if (window.dataLayer && data && '__gaEvents' in data && Array.isArray(data.__gaEvents)) {
        data.__gaEvents.forEach(function (event) {
            pushEvent(event);
        });
    }
}

/**
 * @description Pushes GA event to the data layer
 * @param {Object} data - GA event object
 */
function pushEvent(data) {
    if (window.dataLayer && data && Object.keys(data).length > 0 && document.cookie.indexOf('dw_dnt=0')) {
        data.eventID = generateId(10);
        window.dataLayer.push(data);
    }
}

/**
 * @description Gets GA event object and converts it into JSON
 * @param {Object|string} data - GA event object
 * @param {Element} element - GA element
 * @param {string} elementSelector - List Type selector
 * @returns {Object} JSON parsed result if string
 */
function parseEvent(data, element, elementSelector) {
    var result = data || {};

    if (data && typeof data === 'string') {
        if (element && elementSelector && (data.indexOf('[POSITION]') > -1 || data.indexOf('[LISTTYPE]') > -1)) {
            var container = element.closest('[data-ga-list]') || document;
            var viewElements = container.querySelectorAll(elementSelector);

            if (data.indexOf('[POSITION]') > -1) {
                const arr = Array.from(viewElements);
                var index = arr.indexOf(element);
                data = data.replaceAll('"[POSITION]"', index + 1);
            }

            if (data.indexOf('[LISTTYPE]') > -1) {
                var listType = container.dataset && typeof container.dataset.gaList !== 'undefined'
                    ? container.dataset.gaList
                    : 'empty';
                data = data.replaceAll('[LISTTYPE]', listType);
            }
        }

        try {
            result = JSON.parse(data);
        } catch (e) {
            // IGNORE
        }
    }

    return result;
}

/**
 * Handle custom jQuery events
 */
function handleCustomEvents() {
    $(document).on('adyen:paymentMethodChanged', function(event, data) {
        pushEvent({
            event: 'checkoutOption',
            ecommerce: {
                checkoutOption: {
                    actionField: {
                        step: 5,
                        option: data.name === 'card' ? 'Credit Card' : data.name
                    }
                }
            }
        });
    });
}

/**
 * Handle custom jQuery events for pageInfo object
 */
function handleCheckoutStep() {
    $(document).on('checkout:updateCheckoutStep', function(event, { step, option }) {
        pushEvent({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        action: 'checkout',
                        step,
                        option
                    }
                }
            }
        });
    });
}

/**
 * Handle Subscribe to newsletter step
 */
function handleSubscribeNewsletter() {
    $(document).on('checkout:handleSubscribeNewsletter', function(event, data) {
        pushEvent({
            event: 'sendEvent',
            eventCategory: 'Newsletter',
            eventAction: 'Opt-in',
            eventLabel: data.customerMailHash, // Use SHA-256 decryption type
            eventNonInteraction: false
        });
    });
}

/**
 * @description Updates existing cookieConsentInitial status
 * @param {boolean} trackingStatus - status of tracking
 */
function updateCookieConsentInitialEvent(trackingStatus) {
    const foundEvent = window.dataLayer.find(ev => ev.event === 'cookieConsentInitial');
    if (foundEvent) {
        foundEvent.analytics = trackingStatus;
    }
}

/**
 * Handle custom jQuery events for cookieConsent object
 */
function handleCookieConsentUpdate() {
    $(document).on('cookieConsent:updateCookieConsent', function(event, data) {
        pushEvent(data.cookieConsentData);
        updateCookieConsentInitialEvent(data.cookieConsentData.analytics);
    });
}

/**
 * Gets step one option on cart page
 * @returns {string} step 1 option
 */
function getCheckoutStep1Option() {
    if ($('.js-subscription-option-subscription').hasClass('active')) {
        const periodicity = $('.js-subscription-periodicity').val();

        return 'subscription ' + periodicity;
    }

    return 'one time purchase';

}

/**
 * Exports
 */
module.exports = {
    handleCustomEvents: handleCustomEvents,
    handleCookieConsentUpdate: handleCookieConsentUpdate,
    handleCheckoutStep: handleCheckoutStep,
    handleSubscribeNewsletter: handleSubscribeNewsletter,
    handleAjaxEvents: function () {
        elements.$document.ajaxSuccess(function (e, request, settings, data) {
            if (settings.dataTypes.indexOf('json') > -1) {
                onAjaxResponse(data);
            }
        });
    },
    handleViewEvents: function () {
        onViewInit();

        elements.$document.on('recommendation.slot.loaded', onViewInit);
        elements.$document.on('ga.view.init', onViewInit);

        if (!elements.isInteractionObserver) {
            elements.$document.scroll(function () {
                onViewInit();
            });
        }
    },
    handleClickEvents: function () {
        elements.$document.on('click', '[data-ga-click]', function (e) {
            var isTrackbleAreaClicked = e.target.closest('.js-ga-click-ignore');
            if (window.dataLayer && !isTrackbleAreaClicked) {
                var element = typeof this.dataset.gaClick !== 'undefined' ? this : this.closest('[data-ga-click]');
                var gaObj = element.dataset.gaClick;
                var gaEventObj = parseEvent(gaObj, element, '[data-ga-click]');
                pushEvent(gaEventObj);
            }
        });
    },

    handleCheckoutButtonClick: function () {
        $('.js-checkout-btn').on('click', function() {
            pushEvent({
                event: 'checkoutOption',
                ecommerce: {
                    checkoutOption: {
                        actionField: {
                            step: 1,
                            option: getCheckoutStep1Option()
                        }
                    }
                }
            });
        });
    },

    methods: {
        onAjaxResponse: onAjaxResponse,
        pushEvent: pushEvent
    }
};
